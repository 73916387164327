import React from "react";
import { Pagination } from "react-bootstrap";
import CustomTooltip from "../../utils/CustomTooltip";

const CustomPagination = ({
  activePage,
  onPageChange,
  pageLength,
  totalData,
  lastPage
}) => {
  const pageCount = Math.ceil(totalData / pageLength);
  console.log("pageCount---",pageCount);
  
  const handleFirstClick = () => {
    if (activePage <= 10) {
      onPageChange(null, { activePage: 1 });
    } else {
      onPageChange(null, { activePage: activePage - 10 });
    }
  };

  const handleLastClick = () => {
    if (activePage + 10 >= pageCount) {
      onPageChange(null, { activePage: pageCount });
    } else {
      onPageChange(null, { activePage: activePage + 10 });
    }
  };

  return (
    <div className="pagination" style={{ margin: "auto", justifyContent: "center" }}>
      <Pagination>
      {!isNaN(pageCount) ? 
          <CustomTooltip text="Prev 10"><Pagination.First onClick={handleFirstClick} disabled={activePage <= 1} /></CustomTooltip>
        : null}
        
        <CustomTooltip text="Prev 1"> <Pagination.Prev
          disabled={activePage <= 1}
          onClick={(e) => onPageChange(e, { activePage: activePage - 1 })}
        /></CustomTooltip>
        {pageCount <= 8 ? (
          Array.from({ length: pageCount }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === activePage}
              onClick={(e) => onPageChange(e, { activePage: index + 1 })}
            >
              {index + 1}
            </Pagination.Item>
          ))
        ) : (
          <>

            {Array.from({ length: pageCount }).map((_, index) => {
              if (
                index === activePage - 2 ||
                index === activePage - 1 ||
                index === activePage ||
                index === activePage + 1 ||
                index === activePage + 2
              ) {
                return (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === activePage}
                    onClick={(e) => onPageChange(e, { activePage: index + 1 })}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else {
                return null;
              }
            })}

          </>
        )}
        <CustomTooltip text="Next 1"> <Pagination.Next
          disabled={activePage >= pageCount}
          onClick={(e) => onPageChange(e, { activePage: activePage + 1 })}
        /></CustomTooltip>
        {!isNaN(pageCount) ? 
          <CustomTooltip text="Next 10"><Pagination.Last onClick={handleLastClick} disabled={activePage >= pageCount} /></CustomTooltip>
        : null}
      </Pagination>
    </div>
  );
};

export default CustomPagination;
